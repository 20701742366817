/* custom-quill.css */
.quill {
    max-width: 40rem;
}

.quill .ql-container {
    height: 400px;
}

.quill .ql-toolbar {
    background: white;
}

/* .ql-toolbar .ql-formats button,
.ql-toolbar .ql-formats button span,
.ql-toolbar .ql-formats button svg,
.ql-toolbar .ql-picker-label,
.ql-toolbar .ql-picker-item {
    color: white;
}

.ql-toolbar .ql-formats button svg,
.ql-toolbar .ql-picker-label svg,
.ql-toolbar .ql-picker-item svg path,
.ql-toolbar .ql-picker-label svg polygon {
    fill: white;
    stroke: white;
}

.ql-toolbar .ql-formats button svg path,
.ql-toolbar .ql-formats button svg rect,
.ql-toolbar .ql-formats button svg line,
.ql-toolbar .ql-formats button svg polyline {
    stroke: white;
}

.ql-toolbar .ql-picker-options {
    background-color: #282c34;
}

.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-item.ql-active,
.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active {
    color: #61dafb !important;
}

.ql-toolbar .ql-formats button:hover,
.ql-toolbar .ql-formats button:focus,
.ql-toolbar .ql-formats button:active,
.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label:focus,
.ql-toolbar .ql-picker-label:active,
.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-item:focus,
.ql-toolbar .ql-picker-item:active {
    color: #61dafb !important;
}

.ql-toolbar .ql-formats button:hover svg,
.ql-toolbar .ql-formats button:focus svg,
.ql-toolbar .ql-formats button:active svg,
.ql-toolbar .ql-picker-label:hover svg,
.ql-toolbar .ql-picker-label:focus svg,
.ql-toolbar .ql-picker-label:active svg,
.ql-toolbar .ql-picker-item:hover svg,
.ql-toolbar .ql-picker-item:focus svg,
.ql-toolbar .ql-picker-item:active svg {
    fill: #61dafb !important;
} */